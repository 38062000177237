<template>
  <div class="h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
    <div class="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
      <h1 class="font-bold text-center text-2xl mb-5">Login</h1>
      <div class="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
        <div class="px-5 py-7">
          <label class="font-semibold text-sm text-gray-600 pb-1 block" v-if="!liberarCodigo">E-mail</label>
          <input v-model="form.email" type="email" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" v-if="!liberarCodigo" />
          <label class="font-semibold text-sm text-gray-600 pb-1 block" v-if="!liberarCodigo">{{ $t("txt-password") }}</label>
          <input v-model="form.password" :type="visualizarSenha ? 'text' : 'password'" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" v-if="!liberarCodigo" />
          <label class="font-semibold text-sm text-gray-600 pb-1 block" v-if="liberarCodigo">Código MFA</label>
          <input v-model="form.codigo" type="text" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" v-if="liberarCodigo" />
          <button v-if="!liberarCodigo" @click="visualizarSenha = !visualizarSenha" class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mb-2" v-if="!visualizarSenha">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mb-2" v-else>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
            <span class="text-zinc-600 text-sm mb-3 ml-2">{{ visualizarSenha ? "Ocultar" : "Exibir" }} senha</span>
          </button>
          <button
            @click="liberarCodigo ? autenticacaoDoisFatores() : login()"
            type="button"
            class="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
          >
            <span class="inline-block mr-2">{{ textoBotao }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: function () {
    return {
      form: {
        email: "",
        password: "",
        codigo: ""
      },
      liberarCodigo: false,
      visualizarSenha: false,
      textoBotao: "Entrar"
    };
  },
  methods: {
    async autenticacaoDoisFatores() {
      const resp = await this.$http.post("/login", this.form);
      if (!resp.data.success) {
        this.$vToastify.error(this.$t(resp.data.err));
      } else {
        this.err = false;
        localStorage.setItem("token", resp.data.token);
        localStorage.setItem("user", JSON.stringify(resp.data.user));
        this.$store.commit("login", resp.data.user);

        if (resp.data.user.operador) {
          await this.$http.post("/v1/operador/sessao", { id: resp.data.user._id });
        }

        if (resp.data.user.operador && resp.data.user.chatAcesso) {
          return this.$router.push({ path: "/chats" });
        }

        if (!resp.data.user.chatAcesso && resp.data.user.visualizarChat) {
          return this.$router.push({ path: "/chats" });
        }

        if (resp.data.user.operador && !resp.data.user.chatAcesso) {
          return this.$router.push({ path: "/campanhas" });
        }

        if (resp.data.user.lzv2 && resp.data.user.lzv2.upload) {
          return this.$router.push({ path: "/campanhas" });
        }

        this.$router.push({ path: "/temzap" });
      }
    },

    async login() {
      const resp = await this.$http.post("/login", { email: this.form.email, password: this.form.password });
      if (!resp.data.success) {
        this.$vToastify.error(this.$t(resp.data.err));
      } else if (resp.data.precisaDoisFatores) {
        this.liberarCodigo = true;
        this.textoBotao = "Autenticar";
      } else {
        this.err = false;
        localStorage.setItem("token", resp.data.token);
        localStorage.setItem("user", JSON.stringify(resp.data.user));
        this.$store.commit("login", resp.data.user);

        if (resp.data.user.operador) {
          await this.$http.post("/v1/operador/sessao", { id: resp.data.user._id });
        }

        if (resp.data.user.operador && resp.data.user.chatAcesso) {
          return this.$router.push({ path: "/chats" });
        }

        if (!resp.data.user.chatAcesso && resp.data.user.visualizarChat) {
          return this.$router.push({ path: "/chats" });
        }

        if (resp.data.user.operador && !resp.data.user.chatAcesso) {
          return this.$router.push({ path: "/campanhas" });
        }

        if (resp.data.user.lzv2 && resp.data.user.lzv2.upload) {
          if(resp.data.user.lzv2.respostas && resp.data.user.acessos_lzv2.indexOf('relatorio_chats')) return this.$router.push({ path: "/home" });
          else return this.$router.push({ path: "/campanhas" });
        }

        this.$router.push({ path: "/temzap" });
      }
    }
  },
  async beforeMount() {
    const token = localStorage.getItem("token");
    if (!token) return;

    const user = JSON.parse(localStorage.getItem("user"));

    if (user.lzv2 && user.lzv2.upload) {
      if(user.lzv2.respostas && user.acessos_lzv2.indexOf('relatorio_chats')) return this.$router.push({ path: "/home" });
      else return this.$router.push({ path: "/campanhas" });
    }

    if (!user.chatAcesso) {
      return this.$router.push({ path: "/campanhas" });
    }

    if (!user.chatAcesso && user.visualizarChat) {
      return this.$router.push({ path: "/chats" });
    }

    if (user.operador) {
      return this.$router.push({ path: "/chats" });
    }

    this.$router.push({ path: "/temzap" });
  }
};
</script>
